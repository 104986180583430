import * as honeycombIcons from '@flixbus/honeycomb-icons-react';
import { Icon } from '@flixbus/honeycomb-icons-react';
import {
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  ButtonGroup,
  Divider,
  Fieldset,
  Grid,
  GridCol,
  Heading,
  ListWrapper,
  ListWrapperItem,
  Popup,
  PopupSection,
  Switch,
  Tag,
  Text,
} from '@flixbus/honeycomb-react';
import '@flixbus/honeycomb-react/dist/css/honeycomb-helpers.css';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import useApiService from '../../hooks/useApiService';
import useSubscriptionService from '../../hooks/useSubscriptionService';
import { createApiOverview } from '../../utils/apiUtils';
import { isValidIcon } from '../../utils/honeycombUtils';
import { capitalize, getAPIDescription, getAPINameFromId } from '../../utils/wordUtils';
import Contact from '../layout/Contact';
import './ApiOverview.css';
import Service from './Service';

function ApiOverview() {
  const { id } = useParams();
  const { getApi } = useApiService();
  const { getSubscription, saveSubscription, deleteSubscription } = useSubscriptionService();

  const { data: response, error, isError, isLoading } = useQuery([`api${id}`], () => getApi(id));
  const [popupActive, setPopupActive] = React.useState(null);
  const [fetchSubscription, setFetchSubscription] = React.useState(false);
  const [subscription, setSubscription] = React.useState({
    resourceType: 'api',
    resourceId: id,
    resourceAttributes: [],
  });

  const queryOptions = {
    enabled: !!fetchSubscription,
    onSuccess: (data) => {
      setSubscription(data);
      openSubscriptionsPopup();
      setFetchSubscription(false);
    },
    refetchOnWindowFocus: false,
  };

  // Below query is fired when subscription popup is opened
  useQuery([`subscriptionsQuery`, fetchSubscription], async () => getSubscription(id), queryOptions);

  const handleTab = (event) => {
    // tab trap #1
    // when tab out of last button, return focus to the first button
    if (!event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      event.target.previousSibling.focus();
    }
  };

  const handleShiftTab = (event) => {
    // tab trap #2
    // when shift+tab out of the first button, move focus to the last button
    if (event.shiftKey && event.key === 'Tab') {
      event.preventDefault();
      event.target.nextSibling.focus();
    }
  };

  React.useEffect(() => {
    if (popupActive === true) {
      // When the popup is opened
      // Add focus to the first button of the popup
      document.getElementById('subscriptions-cancel-button').focus();
      // Then block users from scrolling the document body
      document.body.style = 'overflow: hidden;';
    }

    if (popupActive === false) {
      // When the popup is closed,
      // You should return focus to the original target
      document.getElementById('open-subscriptions-button').focus();
      // And then restore scroll ability to document body
      document.body.style = '';
    }
  }, [popupActive]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>API could not be loaded: {error.message}</div>;
  }

  const apiOverview = createApiOverview(response.data);

  const providers = response.data.providers;

  const icon = response.data.icon;
  const apiIcon = isValidIcon(icon) ? honeycombIcons[`Icon${capitalize(icon)}`] : honeycombIcons.IconBus;

  function saveSubscriptions() {
    setPopupActive(false);
    let isSubscribed = document.getElementById('changelogs-switch').checked;

    let attributes = [];
    if (isSubscribed) {
      attributes.push('changes');
    }

    if (attributes.length > 0) {
      saveSubscription('api', response.data.id, attributes);
    } else {
      deleteSubscription('api', response.data.id);
    }
  }

  function openSubscriptionsPopup() {
    setPopupActive(true);
  }

  function toggleSwitch(attribute) {
    let attributes = [];
    if (!subscription.resourceAttributes.includes(attribute)) {
      attributes.push(attribute);
    }
    setSubscription({ resourceType: 'api', resourceId: id, resourceAttributes: attributes });
  }

  return (
    <section className="api-overview-container">
      <section className="api-overview-hero-section">
        <Grid>
          <GridCol>
            <Breadcrumbs aria-label="breadcrumbs" extraClasses="hcr-space-2-bottom">
              <BreadcrumbsItem RouterLink={RouterLink} to="/">
                Home
              </BreadcrumbsItem>
              <BreadcrumbsItem RouterLink={RouterLink} to="/apis">
                APIs
              </BreadcrumbsItem>
              <BreadcrumbsItem>{getAPINameFromId(id)}</BreadcrumbsItem>
            </Breadcrumbs>
          </GridCol>
          <GridCol extraClasses="hcr-has-text-right">
            <Button
              id="open-subscriptions-button"
              appearance="secondary"
              size="sm"
              extraClasses="hcr-space-2-right subscribe-button"
              onClick={() => setFetchSubscription(true)}
            >
              Subscribe
            </Button>
            <Contact type="api" />
          </GridCol>
        </Grid>
        <Grid align="top">
          <GridCol key="grid-btm-a">
            <section className="api-overview-name-tag-group">
              <div>
                <Icon InlineIcon={apiIcon} size={4} style={{ fill: 'var(--hcr-brand-primary-color)' }}></Icon>
              </div>
              <Heading flushSpace size={1} sectionHeader={true} extraClasses="hcr-space-2-left hcr-space-1-right">
                {response.data.name}
              </Heading>
              {providers.length > 0 &&
                providers.map((provider) => {
                  return (
                    <Tag key={provider.name} small extraClasses="api-overview-teams-tags">
                      {provider.name}
                    </Tag>
                  );
                })}
            </section>
            <Grid>
              <GridCol>
                <Text extraClasses="hcr-space-flush-bottom">{getAPIDescription(response.data.description)}</Text>
              </GridCol>
            </Grid>
          </GridCol>
        </Grid>
      </section>
      <section className="api-overview-content">
        <Heading key="basics" size={2}>
          Basics
        </Heading>
        <Grid align="center">
          <GridCol size={2}>
            <ListWrapper small extraClasses="primary-background">
              <ListWrapperItem href={`/apis/${id}/getting-started`}>
                <Text extraClasses="button-text hcr-space-flush-bottom">Getting started</Text>
              </ListWrapperItem>
            </ListWrapper>
          </GridCol>
          <GridCol size={2}>
            <ListWrapper small extraClasses="primary-background basic-buttons">
              <ListWrapperItem href={`/apis/${id}/changelog`}>
                <Text extraClasses="button-text hcr-space-flush-bottom">Changelog</Text>
              </ListWrapperItem>
            </ListWrapper>
          </GridCol>
        </Grid>
        <Divider extraClasses="hcr-space-4-top" />
        <Heading key="endpoints" size={2} extraClasses="hcr-space-flush-bottom">
          Endpoints
        </Heading>
        <Grid align="top" extraClasses="hcr-space-2-bottom">
          {Object.entries(apiOverview).map((entry) => {
            const serviceName = entry[0];
            const endpoints = entry[1];
            return (
              <GridCol key={serviceName} size={2}>
                <Service key={serviceName} title={serviceName} endpoints={endpoints} />
              </GridCol>
            );
          })}
        </Grid>
      </section>
      <Popup aria-labelledby="subscriptions-dialog" active={popupActive} onOverlayClick={() => setPopupActive(false)}>
        <PopupSection type="title">
          <Heading flushSpace id="subscriptions-dialog" size={2}>
            Subscribe to "{response.data.name}"
          </Heading>
        </PopupSection>
        <PopupSection type="content" extraClasses="hcr-space-flush-bottom">
          <br />
          <Fieldset itemSpacing={1}>
            <Switch
              label="Changelogs"
              id="changelogs-switch"
              extraClasses="subscription-switch"
              value="changelogs"
              small
              onChange={() => toggleSwitch('changes')}
              checked={
                subscription.resourceAttributes !== undefined && subscription.resourceAttributes.includes('changes')
              }
            />
            <br />
            <Switch
              label="Examples"
              id="examples-switch"
              extraClasses="subscription-switch"
              value="examples"
              small
              disabled
            />
            <br />
            <br />
          </Fieldset>
        </PopupSection>
        <PopupSection type="actions">
          <ButtonGroup>
            <Button
              id="subscriptions-cancel-button"
              onKeyDown={handleShiftTab}
              onClick={() => {
                setPopupActive(false);
              }}
            >
              Cancel
            </Button>
            <Button
              appearance="primary"
              onKeyDown={handleTab}
              onClick={() => {
                saveSubscriptions();
              }}
            >
              Save
            </Button>
          </ButtonGroup>
        </PopupSection>
      </Popup>
    </section>
  );
}

export default ApiOverview;
